const importPage = (path) => import("@/pages/" + path);
const importLayout = (path) => import("@/layouts/" + path);
export default [
	// 行业培训
	{
		path: "/IndustryTraining/index",
		component: () => importPage("IndustryTraining/index"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	// 培训视频
	{
		path: "/IndustryTraining/pxvideo",
		component: () => importPage("IndustryTraining/pxvideo"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/videodate",
		component: () => importPage("IndustryTraining/videodate"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	// 培训通知
	{
		path: "/IndustryTraining/pxnotice",
		component: () => importPage("IndustryTraining/pxnotice"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/noticedate",
		component: () => importPage("IndustryTraining/noticedate"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/noticelist",
		component: () => importPage("IndustryTraining/noticelist"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	// 选课中心
	{
		path: "/IndustryTraining/course",
		component: () => importPage("IndustryTraining/course"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/course2",
		component: () => importPage("IndustryTraining/course2"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/coursedetails",
		component: () => importPage("IndustryTraining/coursedetails"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/courseinfo",
		component: () => importPage("IndustryTraining/courseinfo"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/courseinfo_new",
		component: () => importPage("IndustryTraining/courseinfo_new"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/courseAudition",
		component: () => importPage("IndustryTraining/courseAudition"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/personal_car",
		component: () => importPage("IndustryTraining/personal_car"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/personal_cartSettlement",
		component: () => importPage("IndustryTraining/personal_cartSettlement"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/videoplay",
		component: () => importPage("IndustryTraining/videoplay"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	// 明星讲师
	{
		path: "/IndustryTraining/lecturer",
		component: () => importPage("IndustryTraining/lecturer"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/lecturerdate",
		component: () => importPage("IndustryTraining/lecturerdate"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/question",
		component: () => importPage("IndustryTraining/question"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	// 个人中心
	{
		path: "/IndustryTraining/personal_infor",
		component: () => importPage("IndustryTraining/personal_infor"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/personal_course",
		component: () => importPage("IndustryTraining/personal_course"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/personal_settlement",
		component: () => importPage("IndustryTraining/personal_settlement"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/personal_order",
		component: () => importPage("IndustryTraining/personal_order"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/personal_invoice",
		component: () => importPage("IndustryTraining/personal_invoice"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
	  path: "/IndustryTraining/wxPay",
	  component: () => importPage("IndustryTraining/wxPay"),
	  meta: { title: "北京兰麦科技有限公司" },
	},
	{
	  path: "/IndustryTraining/aliPay",
	  component: () => importPage("IndustryTraining/aliPay"),
	  meta: { title: "北京兰麦科技有限公司" },
	},
	{
	  path: "/IndustryTraining/aliPayNotify",
	  component: () => importPage("IndustryTraining/aliPayNotify"),
	  meta: { title: "北京兰麦科技有限公司" },
	},
	{
		path: "/IndustryTraining/search",
		component: () => importPage("IndustryTraining/search"),
		meta: { title: "北京兰麦科技有限公司" },
	},
	{
	  path: "/IndustryTraining/wechatScanNotify",
	  component: () => importPage("IndustryTraining/wechatScanNotify"),
	  meta: { title: "北京兰麦科技有限公司" },
	},
	{
	  path: "/IndustryTraining/bindPhone",
	  component: () => importPage("IndustryTraining/bindPhone"),
	  meta: { title: "北京兰麦科技有限公司" },
	},
	{
	  path: "/IndustryTraining/tcplayer",
	  component: () => importPage("IndustryTraining/tcplayer"),
	  meta: { title: "北京兰麦科技有限公司" },
	},
	{
	  path: "/IndustryTraining/video",
	  component: () => importPage("IndustryTraining/video"),
	  meta: { title: "北京兰麦科技有限公司" },
	},
	{
	  path: "/IndustryTraining/videoext",
	  component: () => importPage("IndustryTraining/videoext"),
	  meta: { title: "北京兰麦科技有限公司" },
	},
	{
	  path: "/IndustryTraining/login",
	  component: () => importPage("IndustryTraining/login"),
	  meta: { title: "北京兰麦科技有限公司" },
	},
	{
	  path: "/IndustryTraining/token",
	  component: () => importPage("IndustryTraining/token"),
	  meta: { title: "北京兰麦科技有限公司" },
	},
	{
	  path: "/Wap/denglu",
	  component: () => importPage("Wap/denglu"), 
	  meta: { title: "北京兰麦科技有限公司" },
	},
	{
	  path: "/IndustryTraining/tcplayerext",
	  component: () => importPage("IndustryTraining/tcplayerext"),
	  meta: { title: "北京兰麦科技有限公司" },
	},
	{
	  path: "/IndustryTraining/ccplayerext",
	  component: () => importPage("IndustryTraining/ccplayerext"),
	  meta: { title: "北京兰麦科技有限公司" },
	},
	{
	  path: "/IndustryTraining/retrievePassword",
	  component: () => importPage("IndustryTraining/retrievePassword"),
	  meta: { title: "北京兰麦科技有限公司" },
	},
	{
	  path: "/IndustryTraining/playvideo",
	  component: () => importPage("IndustryTraining/playvideo"),
	  meta: { title: "北京兰麦科技有限公司" },
	},
]